<template>
  <div class="file-download">
    <div class="go-back">
      <span @click="prev">{{i18n.Home.Home}}</span>
    </div>
    <div class="file-download-content">
      <div class="list">
        <section>
          <h3>{{i18n.Home.Recent}}</h3>
          <div class="pagination">
            <i class="el-icon-arrow-left" @click="hanlePrev1"></i>
            <span>{{`${page1}/${totalPage1}`}}</span>
            <i class="el-icon-arrow-right" @click="handleNext1"></i>
          </div>
        </section>

        <ul v-if="list1 && list1.length">
          <li v-for="(item, index) of list1" :key="index">
            <div class="title">
              <span class="title-text">{{ item.title }}</span>
              <span class="new-tag" v-if="item.is_new_flag">NEW</span>
            </div>
            <a
              :href="item.filepath"
              download="true"
              class="download-btn"
              target="_blank"
            />
          </li>
        </ul>
        <Empty v-else />
      </div>
      <div class="list">
        <section>
          <h3>{{i18n.Home.Download}}</h3>
          <div class="pagination">
            <i class="el-icon-arrow-left" @click="hanlePrev2"></i>
            <span>{{`${page2}/${totalPage2}`}}</span>
            <i class="el-icon-arrow-right" @click="handleNext2"></i>
          </div>
        </section>
        <ul v-if="list2 && list2.length">
          <li v-for="(item, index) of list2" :key="index">
            <div class="title">
              <span class="title-text">{{ item.title }}</span>
              <span class="new-tag" v-if="item.is_new_flag">NEW</span>
            </div>
            <a
              :href="item.filepath"
              download="true"
              class="download-btn"
              target="_blank"
            />
          </li>
        </ul>
        <Empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { apiShareFileList } from '@/api/api.js'
import Empty from '@/components/Empty'

export default {
  components: {
    Empty
  },
  data() {
    return {
      list1: [],
      list2: [],
      page1: 1,
      page2: 1,
      totalPage1: 1,
      totalPage2: 2
    }
  },
  created() {
    this.fetchList1()
    this.fetchList2()
  },
   computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    prev() {
      this.$router.push({ name: 'home' })
    },

    hanlePrev1() {
      this.page1 = Math.max(1, this.page1 - 1)
      this.fetchList1()
    },
    handleNext1() {
      this.page1 = Math.min(this.totalPage1, this.page1 +1)
      this.fetchList1()
    },

    hanlePrev2() {
      this.page2 = Math.max(1, this.page2 - 1)
      this.fetchList2()
    },
    handleNext2() {
      this.page2 = Math.min(this.totalPage2, this.page2 +1)
      this.fetchList2()
    },

    fetchList1() {
      this.fetchList(1, this.page1)
    },
    fetchList2() {
      this.fetchList(2, this.page2)
    },
    fetchList(cate, page) {
      apiShareFileList({
        lang: this.$i18n.locale === 'ch' ? 1 : 0,
        cate: cate,
        page_size: 10,
        page: page
      }).then((res) => {
        if (res.code !== 200) {
          return
        }
        if (cate === 1) {
          this.list1 = res.data.list
          this.totalPage1 = res.data.pager.page_total
        } else {
          this.list2 = res.data.list
          this.totalPage2 = res.data.pager.page_total
        }
      })
    }
  }
}
</script>

<style lang="scss">
.file-download {
  .go-back {
    color: #3b86ff;
    padding: 24px 0 20px;
    font-weight: 400;
    font-size: 14px;
    span {
      display: inline-block;
      cursor: pointer;
      &::before {
        content: '<';
        margin-right: 10px;
      }
    }
  }
  .file-download-content {
    display: flex;
    justify-content: center;
  }
  .new-tag {
    display: inline-block;
    width: 44px;
    height: 18px;
    line-height: 18px;
    background: #ffd2dd;
    border-radius: 10px;
    font-size: 12px;
    color: #ad1625;
    margin: 0 16px;
    text-align: center;
  }
  .list {
    flex: 1;
    margin-right: 60px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-right: 0;
    }

    > section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      h3 {
        height: 80px;
        line-height: 80px;
      }
      > div {
        >i {
          margin: 0 4px;
          padding: 10px 2px;
          cursor: pointer;
          color: #347bff;
          font-size: 18px;
        }
      }
    }
  }

  .download-btn {
    width: 12px;
    height: 15px;
    background-image: url(../assets/download-btn.png);
    &:hover {
      background-image: url(../assets/download-btn-active.png);
    }
  }
  ul {
    padding: 0 40px;
    li {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-top: 1px solid #ebebf2;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        width: 90%;
        .title-text {
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          overflow: hidden;
          max-width: 400px;
          line-height: 28px;
          &::before {
            content: '';
            width: 5px;
            height: 5px;
            background: #3b86ff;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 9px;
          }
        }
      }
    }
  }
}
</style>
